
.problem-container {
  ::v-deep .el-button--text {
    color: rgba(17, 34, 216, 1);
  }
  padding: 30px;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  .el-table {
    flex:1;
    margin-top:20px;
  }
  .pages-center {
    text-align: center;
    margin-top: 20px;
  }
}
